let forms = ((w,d) => {

  let forms = d.forms;

  function sendData(e) {
    e.preventDefault();
    let status = e.target.querySelector('.status');
    fetch(e.target.action, {
      method: 'POST',
      headers: {'X-Requested-With': 'XMLHttpRequest'},
      body: new FormData(e.target)
    })
    .then(response => response.ok && response.status == 200 ? response.json() : Promise.reject(`${response.status} ${response.statusText}`))
    .then(json => {
      status.innerHTML = json.status;
      if (json.success) e.target.reset();
     } )
    .catch(err => { status.innerHTML = err } );
  }

  function setEvents() {
    for (let form of forms) {
      form.addEventListener('submit',sendData);
    }
  }
  function init() {
    setEvents();
  }

  return { init }

})(window,document)

export default forms;
