let mobileNav = ((w,d) => {

  var navList, navButton, navBreakpoint;

  function setElements(list,button, breakpoint) {
    navList = list;
    navButton = button;
    navBreakpoint = breakpoint;
  }

  function display() {
    let cond = w.innerWidth <= navBreakpoint;
    navList.classList.toggle('mobile',cond);
    navList.hidden = cond;
    navButton.hidden = !cond;
    navButton.setAttribute('aria-expanded', String(!cond));
  }

  function toggle() {
    navList.hidden = !navList.hidden;
    navButton.setAttribute('aria-expanded', String(!navList.hidden))
  }

  function close(e) {
    let cond = w.innerWidth <= navBreakpoint;
    if (cond && e.target && e.target.closest('a')) toggle();
  }

  function setEvents() {
    d.addEventListener('DOMContentLoaded',display);
    w.addEventListener('resize',display);
    navButton.addEventListener('click',toggle);
    navList.addEventListener('click',close);
  }

  function init(list,button, breakpoint = 480) {
    setElements(list, button, breakpoint);
    setEvents();
  };

  return {init}

})(window,document);

export default mobileNav;
