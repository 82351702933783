let transparentHeader = ((w,d) => {

  var el

  function setElement(element) {
    el = element;
  }

  function setTransparency() {
    let cond = w.pageYOffset <= el.clientHeight;
    el.classList.toggle('transparent',cond)
  }

  function setEvent() {
    d.addEventListener('DOMContentLoaded',setTransparency);
    w.addEventListener('scroll',setTransparency);
  }

  function init(element) {
    setElement(element);
    setEvent();
  }

  return { init }
})(window,document);

export default transparentHeader;
