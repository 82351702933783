import '../sass/styles.scss';
import '@fortawesome/fontawesome-free/js/all.js';
import 'babel-polyfill';
import nav from './mobilenav.mjs';
import parallax from './parallax.mjs';
import header from './header.mjs';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'tippy.js/dist/tippy.css';
import tippy from 'tippy.js';
import forms from './forms.mjs';
import counterUp from 'counterup2';
import 'waypoints/lib/noframework.waypoints.js';

console.info('Hello World');

((w,d) => {
  //preloader
  let preloader = d.querySelector('.preloader');
  let preloaderOpacity = 1;
  let preloaderFade = () => {
    preloaderOpacity -= 0.02;
    preloader.style.opacity = preloaderOpacity;
    if (preloaderOpacity > 0) w.requestAnimationFrame(preloaderFade)
    else preloader.style.display = 'none';
  }
  w.addEventListener('load', w.requestAnimationFrame.bind(w,preloaderFade));

  //mobile.menu
  const navList = d.getElementById('nav-list'),
    navToggle = d.getElementById('nav-toggle'),
    navBreakpoint = navList.dataset.mobileBreakpoint;
  nav.init(navList,navToggle,navBreakpoint);

  //parallaxBg
  const parallaxEl = d.querySelector('.background');
  parallax.init(parallaxEl);

  //header transaprency
  const headerEl = d.querySelector('.header-top');
  header.init(headerEl);

  //counters
  const counterSection = d.querySelector('.counter');
  const counters = counterSection.querySelectorAll('.number');
  new Waypoint({
    element: counterSection,
    handler() {
      counters.forEach(counter => {
        counterUp(counter, {
          duration: 2000,
          delay: 16
        });
      });
      this.destroy()
    },
    offset: 'bottom-in-view'
  })

  //tippy form policy
  let gdprInfoTrigger = d.getElementById('gdpr-info-trigger');
  gdprInfoTrigger.addEventListener('click',(e) => { e.preventDefault(); });
  let gdprInfo = d.getElementById('gdpr-info');
  let tippyInstance = tippy('#gdpr-info-trigger',{
    content: gdprInfo,
    trigger: 'click',
    aria: 'describedby'
  });

  //forms
  forms.init();

  //map
  let mapDiv = d.getElementById('map')
  let address = mapDiv.querySelector('address')
  let dataset = mapDiv.dataset;
  let data = {};
  Object.keys(dataset).forEach((key) => {
    let num = Number(dataset[key]);
    data[key] = isNaN(num) ? dataset[key]  : num;
  });
  let {lat, lng, zoom} = data;

  let map = L.map(mapDiv.id,{
    center: [lat, lng],
    zoom: zoom,
    scrollWheelZoom: false,
    zoomControl: false
  });

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);

  L.control.zoom({
    zoomInTitle: 'Zbliż',
    zoomOutTitle: 'Oddal'
  }).addTo(map);

  L.control.scale({
    position: 'bottomleft',
    maxWidth: 200,
    imperial: false
  }).addTo(map);

  let defaultMarker = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerIconShadow,
    iconAnchor: L.point(12.5,41),
    popupAnchor: L.point(0,-41)
  })

  let marker = L.marker([lat, lng],{
    icon: defaultMarker
  }).addTo(map);

  marker.bindPopup(address);
  marker.bindTooltip('Capital Sp. z o.o.');


})(window,document);
