let parallax = ((w,d) => {

  var el,factor,size, bgInitPos

  function setElement(element) {
    el = element;
    factor = d.body.clientHeight/1080;
    el.style.backgroundSize = `auto 1620px`;
  }

  function setBackground() {
    let bgPos = -w.pageYOffset/factor;
    el.style.backgroundPosition = `50% ${bgPos}px`;
  }

  function setEvent() {
    w.addEventListener('scroll',setBackground);
  }

  function init(element) {
    setElement(element);
    setEvent();
  }

  return { init }
})(window,document);

export default parallax;
